<template>
  <div>
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>


    <v-card rounded="lg">
      <v-card-title>
        <span class="editbox">ADD BIBLE QUOTES</span>
        <v-spacer></v-spacer>
        <v-btn color="red" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-layout wrap justify-center px-2>
            <v-flex xs12 pr-2>
              <span class="label"> Bible Quote </span>
              <v-textarea
                class="pt-2 text-des"
                style="font-size: 14px"
                v-model="verse"
                outlined
                dense
                hide-details
              ></v-textarea>
            </v-flex>

            <v-flex xs12 pr-2 pt-3>
              <span class="label"> Author </span>
              <v-text-field
                class="pt-2 text-des"
                style="font-size: 14px"
                v-model="author"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-flex>

            <v-flex xs12 pr-2 pt-3>
              <span class="label"> Chapter </span>
              <v-text-field
                class="pt-2 text-des"
                style="font-size: 14px"
                v-model="chapter"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-flex>

            <v-flex xs12 pr-2 pt-3>
              <span class="label"> Select Date </span>
              <v-text-field
                outlined
                dense
                hide-details
                :value="formattedDate"
                @click="dialog = true"
                readonly
              ></v-text-field>

              <v-dialog v-model="dialog" max-width="290">
                <v-date-picker
                 :min="nowDate"
                  v-model="showDate"
                  @input="updateDate"
                ></v-date-picker>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4 justify-end headline grey lighten-2">
        <v-btn outlined color="grey" text @click="closeDialog()"
          ><span style="color: black">Cancel</span>
        </v-btn>
        <v-btn color="primary" @click="itemadd()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
//import { VueEditor } from "vue2-editor";
// import ImageComp from "@/components/Common/singleImages";
import axios from "axios";
export default {
  components: {
    //ImageComp,
    // VueEditor,
  },
  data() {
    return {
      showSnackBar:false,
      timeout:5000,
      msg: "",
      dialog: false,
      appLoading: false,
      verse: "",
      chapter: "",
      author: "",
      showDate: "",
      
    };
  },

  //   created() {
  //     this.getDept();
  //     // this.getData(); // Fetch departments when component is created
  //   },
  //   mounted() {
  //     this.getDept();
  //     //this.getCourse();
  //     //this.getCat();
  //     //this.getInst();
  //     this.getData();

  //   },
  methods: {
    closeDialog() {
      this.$emit("stepper", {
        ref: "addBibleQuotes",
        dialog: false,
      });
    },

    saveDialog() {
      this.$emit("stepper", {
        ref: "addBibleQuotes",
        dialog: false,
      });
    },
    updateDate(showDate) {
      this.showDate = showDate;
      this.dialog = false; // Close the dialog after selecting the date
    },

    itemadd() {
      var data = {};
      data["verse"] = this.verse;
      data["chapter"] = this.chapter;
      data["author"] = this.author;
      data["showDate"] = this.showDate;

      axios({
        url: "/add/new/bible/verse",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            this.verse = null;
            this.chapter = null;
            this.author = null;
            this.showDate = null;
            this.saveDialog();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // getData() {
    //   // this.appLoading = true;
    //   axios({
    //     url: "/course/list",
    //     method: "get",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },

    //     params: {
    //       keyword: this.keyword,
    //       limit: this.limit,
    //       page: this.currentPage,
    //       deptId: this.dept,
    //       catId: this.catlist,
    //       subCatId: this.courselist,
    //       instId: this.instlist,

    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;

    //       if (response.data.status) {

    //         this.course = response.data.data;
    //         this.pages = Math.ceil(response.data.totalLength / this.limit);

    //       } else {
    //         this.ServerError = true;
    //         console.log('Error in response:', response.data.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
  computed: {
    formattedDate() {
      // Only format and return the date if one is selected
      return this.showDate ? new Date(this.showDate).toLocaleDateString() : "";
    },
    nowDate() {
      const now = new Date();
      return now.toISOString().substr(0, 10);
    },
  },
};
</script>